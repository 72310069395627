import { useLightbox } from '@mangoweb/react-fslightbox'
import clsx from 'clsx'
import type { GalleryResult } from '../data/GalleryFragment'
import { ContemberImage } from './ContemberImage'
import s from './Gallery.module.sass'

export type GalleryProps = {
	gallery: GalleryResult
}

export function Gallery(props: GalleryProps) {
	const lightbox = useLightbox(props.gallery.items.map((item) => ({ type: 'image', source: item.image?.url })))
	return (
		<div className={clsx(s.wrapper)}>
			{props.gallery.items.map((item, index) => (
				<div key={item.id} className={s.image}>
					{item.image && (
						<button
							type="button"
							onClick={() => {
								lightbox?.open(index)
							}}
							className={s.imageIn}
						>
							<ContemberImage image={item.image} />
						</button>
					)}
				</div>
			))}
		</div>
	)
}
