import clsx from 'clsx'
import { HidingHeader } from 'hiding-header-react'
import 'hiding-header/dist/style.css'
import Link from 'next/link'
import { useState } from 'react'
import type { HeaderLocaleResult } from '../data/HeaderFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Container } from './Container'
import s from './Header.module.sass'
import { Icon } from './Icon'

export type HeaderProps = {
	header: HeaderLocaleResult
	positionFixed: boolean
}

export function Header(props: HeaderProps) {
	const [open, setOpen] = useState(false)

	const content = (
		<Container size="fullWidth">
			<div className={clsx(s.wrapper, props.positionFixed && s.is_position_fixed)}>
				<button onClick={() => setOpen(true)} className={clsx(s.menuBtn, open && s.is_not_visible)}>
					<Icon name="meatBalls" />
				</button>
				<div className={clsx(s.menu, open && s.is_menu_open)}>
					<div className={s.column} />
					<div className={clsx(s.column, s.links)}>
						{props.header.links?.items.map(
							(item) =>
								item.link && (
									<Link
										key={item.id}
										className={s.link}
										{...contemberLinkToHrefTargetRel(item.link)}
										onClick={() => setOpen(false)}
									>
										{item.link.title ?? contemberLinkToHref(item.link)}
									</Link>
								),
						)}
					</div>
					<div className={clsx(s.column, s.closeBtnWrapper)}>
						<button onClick={() => setOpen(false)} className={s.closeBtn}>
							<Icon name="close" />
						</button>
					</div>
				</div>
			</div>
		</Container>
	)

	return !props.positionFixed ? <HidingHeader>{content}</HidingHeader> : content
}
