import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import Link from 'next/link'
import type { FooterLocaleResult } from '../data/FooterFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Container } from './Container'
import s from './Footer.module.sass'

export type FooterProps = {
	footer: FooterLocaleResult
	isHidden?: boolean
}

export function Footer(props: FooterProps) {
	return (
		<Container size="normal">
			<div className={clsx(s.wrapper, props.isHidden && s.isHidden)}>
				{props.footer.links?.items.map(
					(item) =>
						item.link && (
							<div key={item.id} className={s.item}>
								<Link className={s.link} {...contemberLinkToHrefTargetRel(item.link)}>
									{item.link?.title ?? contemberLinkToHref(item.link)}
								</Link>
								{item.text && (
									<div className={s.text}>
										<RichTextRenderer source={item.text} />
									</div>
								)}
							</div>
						),
				)}
			</div>
		</Container>
	)
}
