import { AboutPage } from '../components/AboutPage'
import { Footer } from '../components/Footer'
import { GenericPage } from '../components/GenericPage'
import { Header } from '../components/Header'
import { HomePage } from '../components/HomePage'
import { Project } from '../components/Project'
import { ProjectPage } from '../components/ProjectPage'
import { Seo } from '../components/Seo'
import { AboutPageLocaleFragment } from '../data/AboutPageLocaleFragment'
import { FooterLocaleFragment } from '../data/FooterFragment'
import { GeneralLocaleFragment } from '../data/GeneralLocaleFragment'
import { GenericPageLocaleFragment } from '../data/GenericPageLocaleFragment'
import { HeaderLocaleFragment } from '../data/HeaderFragment'
import { HomePageLocaleFragment } from '../data/HomePageLocaleFragment'
import { LinkFragment } from '../data/LinkFragment'
import { ProjectLocaleFragment } from '../data/ProjectLocaleFragment'
import { ProjectPageLocaleFragment } from '../data/ProjectPageLocaleFragment'
import { contember } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { filterNonEmpty } from '../utilities/filterNonEmpty'
import { getLinkableUrlFromContext } from '../utilities/getLinkableUrlFromContext'
import type { InferDataLoaderProps } from '../utilities/handlers'
import { handleGetStaticPaths, handleGetStaticProps } from '../utilities/handlers'

export type PageProps = InferDataLoaderProps<typeof getStaticProps>

export default function (props: PageProps) {
	const header = props.data.getHeaderLocale
	const { aboutPage, genericPage, homePage, project, projectPage } = props.data.getLinkable ?? {}
	const footer = props.data.getFooterLocale
	return (
		<>
			<Seo {...props.seo} />
			{header && <Header header={header} positionFixed={Boolean(project || homePage)} />}
			{genericPage && <GenericPage genericPage={genericPage} />}
			{aboutPage && <AboutPage aboutPage={aboutPage} />}
			{homePage && (
				<HomePage
					homePage={homePage}
					projects={homePage.root?.projects.map((project) => project.localesByLocale).filter(filterNonEmpty) ?? []}
				/>
			)}
			{project && <Project project={project} />}
			{projectPage && <ProjectPage projectPage={projectPage} projects={props.data.listProjectLocale} />}
			{footer && <Footer footer={footer} isHidden={Boolean(aboutPage)} />}
		</>
	)
}

export const getStaticPaths = handleGetStaticPaths(async (context) => {
	const { listLinkable } = await contember.query({
		listLinkable: [
			{
				filter: {
					redirect: { id: { isNull: true } },
				},
			},
			{
				id: true,
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map((link) => {
			const path = link.url.split('/').filter((part) => part !== '')

			let locale: string | undefined

			if (context.locales?.includes(path[0])) {
				locale = path.shift()
			}

			return {
				locale,
				params: {
					path,
				},
			}
		}),
		fallback: 'blocking',
	}
})

export const getStaticProps = handleGetStaticProps(async (context) => {
	const url = getLinkableUrlFromContext(context)
	const { locale } = context

	if (!locale) {
		throw new Error('Locale not defined.')
	}

	const data = await contember.query({
		getGeneralLocale: [
			{
				by: {
					root: { unique: 'One' },
					locale: { code: locale },
				},
			},
			GeneralLocaleFragment(),
		],
		getHeaderLocale: [
			{
				by: {
					root: { unique: 'One' },
					locale: { code: locale },
				},
			},
			HeaderLocaleFragment(),
		],
		listProjectLocale: [{ orderBy: [{ root: { date: 'desc' } }] }, ProjectLocaleFragment()],
		getLinkable: [
			{
				by: { url },
			},
			{
				url: true,
				aboutPage: [{}, AboutPageLocaleFragment()],
				genericPage: [{}, GenericPageLocaleFragment()],
				homePage: [{}, HomePageLocaleFragment(locale)],
				project: [{}, ProjectLocaleFragment()],
				projectPage: [{}, ProjectPageLocaleFragment()],
				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
		getFooterLocale: [
			{
				by: {
					root: { unique: 'One' },
					locale: { code: locale },
				},
			},
			FooterLocaleFragment(),
		],
	})

	const redirectUrl = (() => {
		const target = data.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
		}
	}

	const canonicalUrl = (() => {
		const url = data.getLinkable?.url
		if (!url) {
			return null
		}
		return (process.env.NEXT_PUBLIC_WEB_URL ?? '') + url
	})()

	const page =
		data.getLinkable?.aboutPage ??
		data.getLinkable?.genericPage ??
		data.getLinkable?.project ??
		data.getLinkable?.projectPage ??
		data.getLinkable?.homePage

	if (!page) {
		return {
			notFound: true,
		}
	}

	return {
		props: {
			data,
			page,
			seo: {
				canonicalUrl,
				seo: {
					...(data.getGeneralLocale?.seo ?? {}),
					...Object.fromEntries(Object.entries(page.seo ?? {}).filter(([_, value]) => Boolean(value))),
				},
			},
		},
		revalidate: 60,
	}
})
