import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { VideoResult } from '../data/VideoFragment'
import { ContemberImage } from './ContemberImage'
import s from './Video.module.sass'

export type VideoProps = {
	video: VideoResult
}

export const Video: FunctionComponent<VideoProps> = ({ video }) => {
	const [isActiveVideo, setIsActiveVideo] = useState(false)
	const [hasUserEverHoveredOverThisVideo, setHasUserEverHoveredOverThisVideo] = useState(false)

	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		const video = videoRef.current
		if (video === null) {
			return
		}
		if (isActiveVideo) {
			try {
				video.play()
			} catch (error) {
				// Play intentionally ignored i don't know what it is
			}
		} else {
			video.pause()
		}
	}, [isActiveVideo])

	return (
		<div
			className={s.wrapper}
			onMouseEnter={() => {
				setHasUserEverHoveredOverThisVideo(true)
			}}
			onMouseLeave={() => {
				console.log('leave')
				setIsActiveVideo(false)
			}}
		>
			<video
				className={s.video}
				ref={videoRef}
				playsInline
				preload={hasUserEverHoveredOverThisVideo ? 'auto' : 'none'}
				muted
			>
				<source src={video.src} />
			</video>

			{video.poster && !isActiveVideo && (
				<button
					className={clsx(s.posterButton, hasUserEverHoveredOverThisVideo && s.is_hidden)}
					onMouseEnter={() => {
						setIsActiveVideo(true)
						videoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
					}}
				>
					<div className={clsx(s.poster)}>
						<ContemberImage image={video.poster} layout="fill" objectFit="cover" />
					</div>
				</button>
			)}
		</div>
	)
}
