import type { GenericPageLocaleResult } from '../data/GenericPageLocaleFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import s from './GenericPage.module.sass'

export type GenericPageProps = {
	genericPage: GenericPageLocaleResult
}

export function GenericPage(props: GenericPageProps) {
	return (
		<Container size="normal">
			<div className={s.wrapper}>
				<h1>{props.genericPage.title}</h1>
				{props.genericPage.content && <ContentRenderer content={props.genericPage.content} />}
			</div>
		</Container>
	)
}
