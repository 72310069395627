import { useCallback, useState } from 'react'
import type { ProjectLocaleResult } from '../data/ProjectLocaleFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Gallery } from './Gallery'
import { Icon } from './Icon'
import s from './Project.module.sass'

export type ProjectProps = {
	project: ProjectLocaleResult
}

export function Project(props: ProjectProps) {
	const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null)

	const onClickFullScreenBtn = useCallback(() => {
		if (videoRef) {
			if ('webkitEnterFullScreen' in videoRef && typeof videoRef.webkitEnterFullScreen === 'function') {
				videoRef.webkitEnterFullScreen()
			} else {
				if ('webkitRequestFullscreen' in videoRef && typeof videoRef.webkitRequestFullscreen === 'function') {
					videoRef.webkitRequestFullscreen()
				} else {
					videoRef.requestFullscreen()
				}
			}
		}
	}, [videoRef])

	return (
		<>
			{props.project.root?.mainVideo?.src ? (
				<div className={s.videoWrapper}>
					<video
						className={s.video}
						src={props.project.root?.mainVideo?.src}
						ref={setVideoRef}
						autoPlay
						muted
						loop
						playsInline
					/>
					<button className={s.fullScreen} onClick={onClickFullScreenBtn}>
						<Icon name="fullScreen" />
					</button>
				</div>
			) : (
				<>
					{props.project?.root?.tileImage && (
						<div className={s.image}>
							<ContemberImage image={props.project.root.tileImage} objectFit="cover" layout="fill" />
						</div>
					)}
				</>
			)}
			<Container size="wide">
				<div className={s.wrapper}>
					<div className={s.titleWrapper}>
						<h1 className={s.title}>{props.project.title}</h1>
						<div className={s.date}>
							{new Intl.DateTimeFormat('en', {
								year: 'numeric',
							}).format(new Date(props.project.root?.date ?? ''))}
						</div>
					</div>
					<div className={s.infoWrapper}>
						<div className={s.infoDescription}>Director</div>
						<div className={s.infoTitle}>{props.project.director}</div>
					</div>
					<div className={s.infoWrapper}>
						<div className={s.infoDescription}>Production</div>
						<div className={s.infoTitle}>{props.project.production}</div>
					</div>
					<div className={s.infoWrapper}>
						<div className={s.infoDescription}>Agency</div>
						<div className={s.infoTitle}>{props.project.agency}</div>
					</div>
					{props.project.gallery && <Gallery gallery={props.project.gallery} />}
				</div>
			</Container>
		</>
	)
}
