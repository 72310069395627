import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Link from 'next/link'
import { useState } from 'react'
import type { HomePageLocaleResult } from '../data/HomePageLocaleFragment'
import type { ProjectLocaleResult } from '../data/ProjectLocaleFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './HomePage.module.sass'
import { Icon } from './Icon'
import { Video } from './Video'

export type HomePageProps = {
	homePage: HomePageLocaleResult
	projects: ProjectLocaleResult[]
}

export function HomePage(props: HomePageProps) {
	const [muted, setMuted] = useState(true)

	return (
		<Container size="fullWidth" disableGutters>
			<div className={s.wrapper}>
				<div className={s.showreelWrapper}>
					<video src={props.homePage.showreel?.src} autoPlay muted={muted} loop playsInline className={s.showreel} />
					<div className={s.mobileGradient} />
					<div className={s.content}>
						<div className={s.title}>{props.homePage.title}</div>
						<h1 className={s.name}>{props.homePage.name}</h1>
						<div className={s.description}>
							{props.homePage.description && <RichTextRenderer source={props.homePage.description} />}
						</div>
						<button className={clsx(s.sound, muted && s.is_icon_visible)} onClick={() => setMuted(false)}>
							<Icon name="soundOff" />
						</button>
						<button className={clsx(s.sound, !muted && s.is_icon_visible)} onClick={() => setMuted(true)}>
							<Icon name="soundOn" />
						</button>
					</div>
				</div>
				<div className={s.mobileSpacer} />
				<div className={s.projects}>
					{props.projects.map((project) => (
						<Link key={project.id} className={s.project} href={project.link?.url ?? ''}>
							{project.root?.mainVideo ? (
								<Video video={project.root.mainVideo} />
							) : (
								<div className={s.image}>
									{project.root?.tileImage && (
										<ContemberImage image={project.root?.tileImage} layout="fill" objectFit="cover" />
									)}
								</div>
							)}
							<div className={s.projectContent}>
								<div className={s.projectTitle}>{project.title}</div>
								<div className={s.projectDirector}>{project.director}</div>
							</div>
						</Link>
					))}
				</div>
				{props.homePage.allProjectsButton && (
					<Link className={s.allProjectsButton} {...contemberLinkToHrefTargetRel(props.homePage.allProjectsButton)}>
						{props.homePage.allProjectsButton?.title ?? contemberLinkToHref(props.homePage.allProjectsButton)}
					</Link>
				)}
			</div>
		</Container>
	)
}
