import clsx from 'clsx'
import Link from 'next/link'
import type { ProjectLocaleResult } from '../data/ProjectLocaleFragment'
import type { ProjectPageLocaleResult } from '../data/ProjectPageLocaleFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import s from './ProjectPage.module.sass'
import { Video } from './Video'

export type ProjectPageProps = {
	projectPage: ProjectPageLocaleResult
	projects: ProjectLocaleResult[]
}

export function ProjectPage(props: ProjectPageProps) {
	const sortedItems = [...props.projects].sort((a, b) => {
		const dateA = a.root?.date ? new Date(a.root.date) : null
		const dateB = b.root?.date ? new Date(b.root.date) : null

		const yearA = dateA ? dateA.getFullYear() : null
		const yearB = dateB ? dateB.getFullYear() : null

		const compareA = Number(`${yearA}${a.root?.mainVideo ? 1 : 0}`)
		const compareB = Number(`${yearB}${b.root?.mainVideo ? 1 : 0}`)

		return compareB - compareA
	})

	console.log(sortedItems)

	return (
		<Container size="wide" disableGutters>
			<Container size="wide">
				<div className={s.wrapper}>
					<h1 className={s.title}>{props.projectPage.title}</h1>
				</div>
			</Container>
			<div className={s.projects}>
				<Container size="wide">
					<div className={s.legend}>
						<div className={s.spacer}></div>
						<div>Year</div>
						<div>Title</div>
						<div>Director</div>
						<div>Production</div>
						<div>Agency</div>
					</div>
				</Container>
				<div className={s.projectsMobile}>
					{sortedItems.map((project) => (
						<Link href={project.link?.url ?? ''} key={project.id} className={s.project}>
							{project.root?.mainVideo ? (
								<div className={s.video}>
									<Video video={project.root.mainVideo} />
								</div>
							) : (
								<div className={s.image}>
									{project.root?.tileImage && (
										<ContemberImage image={project.root?.tileImage} layout="fill" objectFit="cover" />
									)}
								</div>
							)}
							<div className={clsx(s.projectInfo, project.root?.mainVideo && s.hasVideo)}>
								<div className={s.projectTitle}>
									{project.root?.mainVideo ? (
										<div className={s.titleIcon}>
											<Icon name="play" />
										</div>
									) : (
										<div className={s.titleIcon}>
											<Icon name="arrow" />
										</div>
									)}
									{project.title}
								</div>
								<div className={s.itemWrapperBox}>
									<div className={s.itemWrapper}>
										<div className={s.legendItemMobile}>Director:</div>
										<div className={s.item}>{project.director}</div>
									</div>
									<div className={s.itemWrapper}>
										<div className={s.legendItemMobile}>Production:</div>
										<div className={s.item}>{project.production}</div>
									</div>
								</div>
							</div>
						</Link>
					))}
				</div>
				<Container size="wide">
					<div className={s.projectsDesktop}>
						{sortedItems.map((project) => (
							<Link href={project.link?.url ?? ''} key={project.id} className={s.project}>
								<div className={clsx(s.itemWrapper, s.image)}>
									{project.root?.tileImage && (
										<ContemberImage image={project.root.tileImage} layout="fill" objectFit="cover" />
									)}
								</div>
								<div className={s.itemWrapper}>
									<div className={s.legendItemMobile}>Year:</div>
									<div className={s.item}>
										{project.root?.date &&
											new Intl.DateTimeFormat('cs', {
												year: 'numeric',
											}).format(new Date(project.root.date))}
										<span className={s.arrowIcon}>
											<Icon name="arrow" />
										</span>
									</div>
								</div>
								<div className={s.itemWrapper}>
									<div className={s.legendItemMobile}>Title:</div>
									<div className={s.item}>{project.title}</div>
								</div>
								<div className={s.itemWrapper}>
									<div className={s.legendItemMobile}>Director:</div>
									<div className={s.item}>{project.director}</div>
								</div>
								<div className={s.itemWrapper}>
									<div className={s.legendItemMobile}>Production:</div>
									<div className={s.item}>{project.production}</div>
								</div>
								<div className={s.itemWrapper}>
									<div className={s.legendItemMobile}>Agency:</div>
									<div className={s.item}>{project.agency}</div>
								</div>
							</Link>
						))}
					</div>
				</Container>
			</div>
		</Container>
	)
}
