import { RichTextRenderer } from '@contember/react-client'
import Link from 'next/link'
import type { AboutPageLocaleResult } from '../data/AboutPageLocaleFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import s from './AboutPage.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'

export type AboutPageProps = {
	aboutPage: AboutPageLocaleResult
}

export function AboutPage(props: AboutPageProps) {
	return (
		<Container size="wide">
			<div className={s.container}>
				<div className={s.wrapper}>
					<div className={s.nameWrapper}>
						<h1 className={s.name}>{props.aboutPage.name}</h1>
						{props.aboutPage.text && (
							<div className={s.text}>
								<RichTextRenderer source={props.aboutPage.text} />
							</div>
						)}
					</div>
					{props.aboutPage.image && (
						<div className={s.image}>
							<ContemberImage image={props.aboutPage.image} />
						</div>
					)}
				</div>
				<div className={s.footer}>
					{props.aboutPage.footerColumns?.items.map(
						(item) =>
							item.link && (
								<div key={item.id} className={s.footerItem}>
									<Link className={s.footerLink} {...contemberLinkToHrefTargetRel(item.link)}>
										{item.link?.title ?? contemberLinkToHref(item.link)}
									</Link>
									{item.text && (
										<div className={s.footerText}>
											<RichTextRenderer source={item.text} />
										</div>
									)}
								</div>
							),
					)}
				</div>
			</div>
		</Container>
	)
}
