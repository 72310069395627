// Reexport all svg icons
export { default as arrow } from './arrow.svg'
export { default as close } from './close.svg'
export { default as fullScreen } from './fullScreen.svg'
export { default as instagram } from './instagram.svg'
export { default as linkedin } from './linkedin.svg'
export { default as meatBalls } from './meatBalls.svg'
export { default as play } from './play.svg'
export { default as soundOff } from './soundOff.svg'
export { default as soundOn } from './soundOn.svg'
export { default as twitter } from './twitter.svg'
